.app * {
	box-sizing: border-box;
	position: relative;
	font-family: Mont, sans-serif;
	font-weight: 400;
	font-style: normal;
	white-space: pre-wrap;
}

body {
	overflow: hidden;
}

.app a {
	text-decoration: none;
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
}

button {
	border: transparent;
	outline: none;
	background: transparent;
}

.btn {
	font-size: 0.833vw;
	color: #000;
	cursor: pointer;
	font-weight: 600;
	text-align: center;
	@media #{$mobileQuery} {
		font-size: 3.733vw;
	}
	&.btn-with-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		svg,
		img {
			margin-right: 0.521vw;
		}
		span {
			font-weight: 600;
		}
	}
	&.btn-tranparent {
		background: transparent;
		border: transparent;
	}
	&.btn-link {
		&::before {
			content: '';
			position: absolute;
			top: calc(100% + 1px);
			left: 0;
			width: 0;
			height: 1px;
			background: #000;
			transition: width 0.3s;
		}
		&:hover {
			&::before {
				width: 100%;
			}
		}
		&.btn-footer {
			color: #fff;
			&::before {
				background: #fff;
			}
		}
	}
	&.btn-primary {
		background: $mainColor;
		color: #fff;
		padding: 0 1.771vw;
		border-radius: 1000px;
		transition: all 0.3s;
		height: 2.5vw;
		display: flex;
		align-items: center;
		justify-content: center;
		@media #{$mobileQuery} {
			padding: 0 9.333vw;
			height: 12.8vw;
		}
		&:hover {
			background: $mainColorDarker;
		}
		&.btn-outlined {
			border: 2px solid $mainColor;
			background: transparent;
			color: $mainColor;
			&:hover {
				background: $mainColor;
				color: #fff;
			}
		}
	}
	&.btn-icon {
		svg {
			rect {
				transition: fill 0.3s;
			}
		}
	}
	&.btn-outlined {
		border-radius: 1000px;
		padding: 0 1.771vw;
		transition: all 0.3s;
		display: flex;
		height: 2.5vw;
		align-items: center;
		justify-content: center;
		@media #{$mobileQuery} {
			padding: 0 9.333vw;
			height: 12.8vw;
		}
		&.btn-footer {
			color: #fff;
			border: 2px solid #fff;
		}
		&:hover {
			border: 2px solid transparent;
			background: $mainColor;
		}
	}
}

.title-holder-wrap {
	width: 100%;
	height: 7.813vw;
	margin-top: 3.49vw;
	margin-bottom: 6.51vw;
	@media #{$mobileQuery} {
		margin-left: -5.333vw;
		height: 35px;
	}
}

.title-holder {
	width: 100%;
	margin-left: -1.042vw;
	position: absolute;
	top: 0;
	left: 0;
	padding-left: 1.042vw;
	display: flex;
	@media #{$mobileQuery} {
		padding: 2vw 0;
		padding-left: 5.333vw;
		width: 100vw;
	}
	.curtains-canvas {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 1;
	}
	.logoPlane {
		img {
			opacity: 0;
		}
	}
	img {
		width: 76.042vw;
		height: 7.813vw;
		@media #{$mobileQuery} {
			width: 90.667vw;
			height: 9.333vw;
		}
	}
}

.app {
	width: 100%;
	padding: 3.125vw 0 0 0;
	@media #{$mobileQuery} {
		padding: 8vw 0 0 0;
	}
}

.section {
	display: flex;
	width: 100%;
	padding: 0 3.125vw;
	flex-direction: column;
	color: #000;
	@media #{$mobileQuery} {
		padding: 0 5.333vw;
	}
	.section-container {
		width: 100%;
	}
}
