.section.section-contact {
	padding-bottom: 10.417vw;
	@media #{$mobileQuery} {
		padding-bottom: 5.333vw;
	}
	.contact-details {
		width: 100%;
		display: flex;
		gap: 3.646vw;
		align-items: center;
		@media #{$mobileQuery} {
			gap: 10.667vw;
			flex-direction: column-reverse;
		}
		.side {
			width: 100%;
			&.side-map {
				width: 42.917vw;
				height: 24.167vw;
				flex-shrink: 0;
				@media #{$mobileQuery} {
					width: 100%;
					height: auto;
				}
				.map-container {
					width: 100%;
					height: 100%;
					@media #{$mobileQuery} {
						height: 68.8vw;
					}
					.map {
						width: 100%;
						height: 100%;
					}
				}
				.logo-mobile {
					display: none;
					justify-content: center;
					width: 100%;
					margin-top: 5.333vw;
					@media #{$mobileQuery} {
						display: flex;
					}
					img {
						width: 8vw;
					}
				}
			}
			&.side-details {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 3.125vw;
				@media #{$mobileQuery} {
					gap: 8vw;
				}
				.detail {
					font-weight: 800;
					font-size: 1.354vw;
					color: #000;
					line-height: 1.458vw;
					display: flex;
					@media #{$mobileQuery} {
						font-size: 4.8vw;
						line-height: 5.067vw;
						font-weight: 600;
					}
					a {
						font-weight: 800;
						font-size: 1.354vw;
						color: #000;
						line-height: 1.458vw;
						@media #{$mobileQuery} {
							font-size: 4.8vw;
							line-height: 5.067vw;
							font-weight: 600;
						}
						&::before {
							content: '';
							position: absolute;
							top: calc(100% + 0.156vw);
							height: 0.156vw;
							background: #000;
							width: 0;
							transition: width 0.3s;
						}
						&:hover {
							&::before {
								width: 100%;
								@media #{$mobileQuery} {
									width: 0;
								}
							}
						}
					}
					&.socials {
						gap: 0.26vw;
						@media #{$mobileQuery} {
							gap: 1.067vw;
						}
					}
				}
				.btn.cta {
					justify-content: flex-start;
					gap: 1.042vw;
					@media #{$mobileQuery} {
						gap: 2.667vw;
					}
					svg {
						flex-shrink: 0;
						width: 3.438vw;
						height: 3.438vw;
						margin: 0;
						@media #{$mobileQuery} {
							width: 8vw;
							height: 8vw;
						}
					}
					span {
						font-weight: 800;
						font-size: 1.042vw;
						color: #000;
						@media #{$mobileQuery} {
							font-size: 4.8vw;
							font-weight: 600;
						}
					}
					&:hover {
						svg {
							circle:first-child {
								fill: $mainColorDarker;
							}
						}
					}
				}
				.logo {
					position: absolute;
					top: calc(100% - 0.521vw);
					right: 3.125vw;
					width: 7.604vw;
					@media #{$mobileQuery} {
						display: none;
					}
					img {
						width: 100%;
					}
				}
			}
		}
	}
}
