.section.section-services {
	.services {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding-bottom: 8.333vw;
		@media #{$mobileQuery} {
			padding-bottom: 18.667vw;
		}
		.service {
			display: flex;
			flex-direction: column;
			margin-left: -3.125vw;
			width: calc(100% + 6.25vw);
			padding: 0 3.125vw;
			overflow: hidden;
			transition: all 0.3s;
			@media #{$mobileQuery} {
				margin-left: -5.333vw;
				width: calc(100% + 10.667vw);
				padding: 0 5.333vw;
			}
			.header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 2.083vw 0;
				@media #{$mobileQuery} {
					padding: 8vw 0;
				}
				.title {
					font-weight: 600;
					font-size: 3.125vw;
					line-height: 3.958vw;
					color: #000;
					@media #{$mobileQuery} {
						font-size: 8vw;
						line-height: 10.133vw;
					}
				}
				.open-button {
					display: flex;
					align-items: center;
					width: 1.042vw;
					height: 1.042vw;
					justify-content: flex-end;
					cursor: pointer;
					@media #{$mobileQuery} {
						width: 5.333vw;
						height: 5.333vw;
					}
					img {
						width: 3.2vw;
					}
				}
			}
			p {
				padding: 1.042vw 0 2.083vw;
				font-size: 1.979vw;
				line-height: 2.5vw;
				font-weight: 600;
				color: #000;
				margin: 0;
				@media #{$mobileQuery} {
					padding: 2.667vw 0 8vw;
					font-weight: 600;
					font-size: 5.333vw;
					line-height: 6.933vw;
				}
			}
			&:nth-child(2n + 2) {
				background: #f5f5f5;
			}
			&.active {
				.header {
					.open-button {
						img {
							transform: rotate(90deg);
						}
					}
				}
			}
		}
	}
}
