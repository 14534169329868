.section-navigation {
	display: flex;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 1.042vw;
	@media #{$mobileQuery} {
		margin-bottom: 0;
	}
	.btn-holder {
		&.toggle-sound {
			.btn.btn-with-icon {
				transition: color 0.3s;
				svg {
					width: 0.781vw;
					@media #{$mobileQuery} {
						width: 15px;
						margin-right: 0;
					}
					rect {
						transition: fill 0.3s;
					}
				}
				span {
					@media #{$mobileQuery} {
						display: none;
					}
				}
				&::after {
					content: '';
					width: 0;
					height: 0.156vw;
					top: 50%;
					margin-top: -0.078vw;
					left: -0.521vw;
					background: $feedBackGrey;
					position: absolute;
					transition: width 0.3s;
					@media #{$mobileQuery} {
						height: 2px;
						margin-top: -1px;
						left: -5px;
					}
				}
				&.deactivated {
					color: $feedBackGrey;
					@media #{$mobileQuery} {
						&:hover {
							svg {
								rect {
									fill: $feedBackGrey;
								}
							}
							&::after {
								width: calc(100% + 10px);
							}
						}
					}
					svg {
						rect {
							fill: $feedBackGrey;
							@media #{$mobileQuery} {
								color: $feedBackGrey;
							}
						}
					}
					&::after {
						width: calc(100% + 0.938vw);
						@media #{$mobileQuery} {
							width: calc(100% + 10px);
						}
					}
				}
				&:hover {
					color: $mainColor;
					svg {
						rect {
							fill: $mainColor;
							@media #{$mobileQuery} {
								fill: #000;
							}
						}
					}
					&::after {
						width: 0;
					}
				}
			}
		}
		&.nav-phone {
			margin-left: auto;
			@media #{$mobileQuery} {
				margin-left: auto;
				margin-right: 5.333vw;
			}
			.btn.btn-with-icon {
				span{
					@media #{$mobileQuery}{
						font-size: 4.267vw;
						font-weight: 800;
						line-height: 1;
						margin-top: 2.667vw;
					}
				}
				svg {
					width: 0.521vw;
					@media #{$mobileQuery}{
						display: none;
					}
				}
			}
		}
		&.request-price {
			margin-right: 0;
			margin-left: 1.979vw;
			@media #{$mobileQuery} {
				display: none;
			}
		}
		&.open-menu {
			margin-right: 0;
			margin-left: 2.083vw;
			@media #{$mobileQuery} {
				margin-left: 0;				
			}
			.btn.btn-icon {				
				svg {
					width: 1.667vw;
					@media #{$mobileQuery} {
						width: 5.333vw;
					}
				}
				&:hover {
					svg {
						rect {
							fill: $mainColor;
						}
					}
				}
			}
		}
	}
	.back-to-main-btn {
		width: 4.115vw;
		margin-left: 2.604vw;
		@media #{$mobileQuery} {
			margin-left: 5.333vw;
			margin-right: auto;
			width: 21.067vw;
		}
		img {
			width: 100%;
		}
	}
}
