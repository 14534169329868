.section-home {
	display: flex;
	flex-direction: column;
	.logo-holder {
		width: 100%;
		height: 20.833vw;
		display: flex;
		justify-content: center;
		padding: 3.229vw 0 5.365vw;
		@media #{$mobileQuery} {
			padding: 10.667vw 0;
			height: 40vw;
		}
		.curtains-canvas {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			z-index: 1;
		}
		.logoPlane {
			img {
				opacity: 0;
			}
		}
		img {
			width: 44.625vw;
			@media #{$mobileQuery} {
				width: 69.333vw;
			}
		}
	}
	.cases-wrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		.categories {
			width: 100%;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			gap: 5.573vw;
			margin-bottom: 3.125vw;
			@media #{$mobileQuery} {
				display: none;
			}
			.cat {
				font-size: 0.938vw;
				font-weight: 600;
				color: #000;
				transition: color 0.3s;
				cursor: pointer;
				&::before {
					content: '';
					width: 0;
					height: 0.104vw;
					position: absolute;
					top: calc(100% + 0.365vw);
					background: transparent;
					transition: all 0.3s;
				}
				&:hover {
					color: $mainColorDarker;
					&::before {
						width: 100%;
						background: $mainColorDarker;
					}
				}
				&.active {
					color: $mainColor;
					&::before {
						width: 100%;
						background: $mainColor;
					}
				}
			}
		}
		.mobile-categories {
			width: 100%;
			height: auto;
			display: none;
			justify-content: center;
			margin-bottom: 10.667vw;
			z-index: 1;
			height: 12.8vw;
			@media #{$mobileQuery} {
				display: flex;
			}
			.categories-wrap {
				width: 65.067vw;
				height: 12.8vw;
				overflow: hidden;
				transition: all 0.3s;
				position: absolute;
				top: 0;
				border-radius: 6.8vw;
				.chosen-cat {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 6.8vw;
					position: absolute;
					top: 0;
					left: 0;
					height: 12.8vw;
					border: 1px solid $mainColor;
					color: $mainColor;
					background: #fff;
					transition: all 0.3s;
					span {
						font-size: 3.733vw;
						font-weight: 600;
					}
					svg {
						transition: all 0.3s;
						width: 2.667vw;
						position: absolute;
						right: 4vw;
						transform: rotate(-90deg);
						path {
							transition: all 0.3s;
						}
					}
				}
				.cat-list {
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					display: flex;
					flex-direction: column;
					align-items: center;
					background: #fff;
					border-radius: 6.8vw;
					padding: 12.267vw 5.333vw 2.667vw 5.333vw;
					.cat {
						width: 100%;
						text-align: center;
						padding: 5.333vw 0;
						font-size: 3.733vw;
						font-weight: 600;
						border-top: 1px solid $feedBackGrey;
						&:first-child {
							border-top: transparent;
						}
					}
				}
				&.open {
					box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
					.chosen-cat {
						background: $mainColor;
						color: #fff;
						svg {
							transform: rotate(0);
							path {
								fill: #fff;
							}
						}
					}
				}
			}
		}
		.cases {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			gap: 1vw 0;
			justify-content: space-between;
			@media #{$mobileQuery} {
				gap: 1.867vw;
			}
			.case {
				height: 26.042vw;
				width: 24.25%;
				cursor: pointer;
				@media #{$mobileQuery} {
					height: 50.133vw;
					width: 100%;
					&.mobile-half {
						width: 43.733vw;
					}
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
				.desc-block {
					position: absolute;
					top: 0;
					left: 0;
					width: 0;
					height: 0;
					color: #fff;
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					overflow: hidden;
					// opacity: 0;
					@media #{$mobileQuery} {
						display: none;
					}
					.bg {
						position: absolute;
						width: 0;
						height: 0;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						background: #000;
						border-radius: 50%;
						transition: all 0.3s linear;
					}
					.wrap {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						display: flex;
						flex-direction: column;
						padding: 2.083vw 1.563vw;
						opacity: 0;
						top: 1.042vw;
						transition: all 0.3s 0.3s;
					}
					.title {
						font-size: 1.042vw;
						line-height: 1.354vw;
						font-weight: 800;
						margin-top: 0;
						margin-right: 0;
					}
					.description,
					.cat-title {
						font-size: 0.833vw;
						line-height: 1.042vw;
						font-weight: 600;
						margin-top: 1.042vw;
						margin-bottom: auto;
					}
					.description {
						white-space: pre-wrap;
					}
					.cat-title {
						margin-top: auto;
						margin-bottom: 0;
					}
				}
				&:hover {
					.desc-block {
						// opacity: 1;
						border-radius: 0;
						.bg {
							width: 70vw;
							height: 70vw;
						}

						.wrap {
							opacity: 1;
							top: 0;
						}
					}
				}

				&:nth-child(2),
				&:nth-child(10),
				&:nth-child(11),
				&:nth-child(12),
				&:nth-child(13),
				&:nth-child(14),
				&:nth-child(19),
				&:nth-child(20),
				&:nth-child(22n + 22),
				&:nth-child(30n + 30),
				&:nth-child(31n + 31),
				&:nth-child(32n + 32),
				&:nth-child(33n + 33),
				&:nth-child(34n + 34),
				&:nth-child(39n + 39),
				&:nth-child(40n + 40) {
					width: 49.5%;
					@media #{$mobileQuery} {
						height: 50.133vw;
						width: 100%;
						&.mobile-half {
							width: 43.733vw;
						}
					}
				}
			}
		}
		.btn-holder {
			margin-top: 5.208vw;
			width: 100%;
			display: flex;
			justify-content: center;
			@media #{$mobileQuery} {
				margin-top: 10.667vw;
			}
		}
	}
	.blocks {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 6.25vw;
		@media #{$mobileQuery} {
			margin-top: 17.867vw;
		}
		.block {
			width: 100%;
			display: flex;
			flex-direction: column;
			h3 {
				margin: 0;
				font-size: 0.938vw;
				font-weight: 700;
				margin-bottom: 3.125vw;
				@media #{$mobileQuery} {
					font-size: 4.8vw;
					margin-bottom: 12.533vw;
				}
			}
			&.awards {
				.award-list {
					width: 100%;
					display: flex;
					flex-direction: column;
					.row {
						display: flex;
						margin-bottom: 2.969vw;
						margin-left: -3.125vw;
						width: calc(100% + 6.25vw);
						@media #{$mobileQuery} {
							margin-bottom: 15.2vw;
							margin-left: -5.333vw;
							width: calc(100% + 10.667vw);
						}
						.row-content {
							flex-shrink: 0;
							display: flex;
							.award {
								flex-shrink: 0;
								display: flex;
								flex-wrap: wrap;
								align-items: center;
								span {
									text-transform: uppercase;
									font-size: 1.042vw;
									font-weight: 700;
									margin-right: 0.625vw;
									@media #{$mobileQuery} {
										font-size: 5.333vw;
										margin-right: 3.2vw;
									}
								}
								img {
									max-width: 2.344vw;
									margin-right: 0.625vw;
									@media #{$mobileQuery} {
										max-width: 12vw;
										margin-right: 3.2vw;
									}
								}
							}
						}
					}
				}
			}
			&.clients-block {
				margin-top: 3.281vw;
				margin-bottom: 6.771vw;
				@media #{$mobileQuery} {
					margin-top: 17.867vw;
					margin-bottom: 18.667vw;
				}
				.client-list {
					width: 100%;
					display: flex;
					flex-direction: column;
					.row {
						width: 100%;
						display: flex;
						align-items: center;
						// gap: 3.646vw;
						margin-bottom: 2.604vw;
						transform: translateX(-30%);
						justify-content: space-between;
						@media #{$mobileQuery} {
							transform: translateX(0);
							margin-bottom: 0;
							flex-wrap: wrap;
							justify-content: flex-start;
						}
						.client {
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							@media #{$mobileQuery} {
								margin-bottom: 10.667vw;
								width: 33.3333%;
								justify-content: center;
								&:nth-child(3n + 4),
								&:first-child {
									padding-left: 0;
								}
							}
							img {
								max-width: 5.417vw;
								max-height: 4.167vw;
								@media #{$mobileQuery} {
									max-width: 18.667vw;
									max-height: 13.333vw;
								}
							}
						}
						&:nth-child(even) {
							transform: translateX(30%);
							padding-left: 3.125vw;
							@media #{$mobileQuery} {
								transform: translateX(0);
								padding-left: 0;
							}
						}
					}
				}
			}
		}
	}
}
