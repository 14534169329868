.section.section-case {
	@media #{$mobileQuery} {
		margin-top: 10.667vw;
	}
	.title {
		font-size: 3.125vw;
		line-height: 3.385vw;
		font-weight: 800;
		color: #000;
		margin-bottom: 2.344vw;
		@media #{$mobileQuery} {
			font-weight: 900;
			font-size: 6.4vw;
			line-height: 8.267vw;
			margin-bottom: 10.667vw;
		}
	}
	.subtitle {
		font-weight: 600;
		font-size: 2.083vw;
		line-height: 2.656vw;
		margin-bottom: 5.208vw;
		@media #{$mobileQuery} {
			font-weight: 800;
			font-size: 4.8vw;
			line-height: 6.133vw;
			margin-bottom: 8vw;
			white-space: pre-wrap;
		}
	}
	.blocks {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 5.208vw;
		margin-bottom: 10.417vw;
		@media #{$mobileQuery} {
			gap: 8vw;
			margin-bottom: 13.333vw;
		}
		.block {
			width: 100%;
			display: flex;
			align-items: flex-start;
			&.block-text {
				@media #{$mobileQuery} {
					flex-direction: column;
					gap: 8vw;
				}
				label {
					width: 50%;
					font-weight: 600;
					font-size: 1.563vw;
					line-height: 1.771vw;
					color: #000;
					@media #{$mobileQuery} {
						width: 100%;
						font-size: 4.8vw;
						line-height: 113.55%;
					}
				}
				.text {
					// max-width: 800px;
					// @media #{$mobileQuery} {
					// 	max-width: 100%;
					// }
					white-space: pre-wrap;
					p {
						margin: 0;
						font-size: 0.938vw;
						line-height: 1.198vw;
						font-weight: 600;
						color: #000;
						@media #{$mobileQuery} {
							font-size: 4.267vw;
							line-height: 5.333vw;
						}
					}
				}
			}
			&.block-img {
				margin-left: -3.125vw;
				width: calc(100% + 6.25vw);
				@media #{$mobileQuery} {
					margin-left: -5.333vw;
					width: calc(100% + 10.667vw);
				}
				img {
					width: 100%;
				}
			}
			&.block-gallery {
				width: 100%;
				.imgs {
					margin-left: -3.125vw;
					margin-right: -3.125vw;
					width: calc(100% + 6.25vw);
					@media #{$mobileQuery} {
						width: calc(100% + 10.667vw);
						margin-left: -5.333vw;
					}
					img {
						width: 100%;
						margin-bottom: 1.302vw;
						@media #{$mobileQuery} {
							margin-bottom: 1.333vw;
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
			&.block-video {
				margin-left: -3.125vw;
				width: calc(100% + 6.25vw);
				height: 49.219vw;
				@media #{$mobileQuery} {
					margin-left: -5.333vw;
					width: calc(100% + 10.667vw);
					height: 49.333vw;
				}
				iframe {
					width: 100%;
					height: 100%;
				}
			}
			&.block-uploaded-video {
				margin-left: -3.125vw;
				width: calc(100% + 6.25vw);
				@media #{$mobileQuery} {
					margin-left: -5.333vw;
					width: calc(100% + 10.667vw);
				}
				video {
					width: 100%;
				}
			}
		}
	}

	.btn-holder {
		display: flex;
		justify-content: center;
		margin-bottom: 10.417vw;
		@media #{$mobileQuery} {
			margin-bottom: 13.333vw;
		}
	}
}
